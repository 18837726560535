<template>
    <div>
    <!-- <el-row class="bgW">
        <el-col :span="22">
            <el-form class="search" label-suffix="：" :inline="true" size="mini" :model="search">
                <el-form-item label="排班月份">
                    <el-date-picker
                        v-model="search.create_time_start"
                        type="date"
                        class="w130"
                        placeholder="排班月份"
                        value-format="yyyy-MM"
                    ></el-date-picker>
                    
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right;">
            <el-button type="primary" icon="el-icon-search" size="mini" @click="">搜索</el-button>
        </el-col>
    </el-row> -->
    <el-row>
        <el-col :span="24">
            <el-table
                :data="list"
                size="big"
                border
                :cell-style="$style.cellStyle"
                :header-cell-style="$style.rowClass"
            >
                
                <ElTableColumn label="员工姓名" width="150" prop="name"  fixed="left"/>
    
            
                
                <ElTableColumn v-for="item in workDate" :label="item" width="80">
                    <template slot-scope="{ row }" >
                        <div
                            class="hand hand-color1"
                            @click="clickGrids(row.id,row.year,item,row[item])"
                            v-if="row[item] == 0"
                        >休</div>
    
                        <div
                            class="hand hand-color2"
                            @click="clickGrids(row.id,row.year,item,row[item])"
                            v-else-if="row[item] == 1"
                        >全天班</div>
    
                        <div
                            class="hand hand-color3"
                            @click="clickGrids(row.id,row.year,item,row[item])"
                            v-else-if="row[item] == 2"
                        >上午班</div>
    
                        <div
                            class="hand hand-color4"
                            @click="clickGrids(row.id,row.year,item,row[item])"
                            v-else
                        >下午班</div>
                    </template>
                </ElTableColumn>
    
                <ElTableColumn  label="月度排班" fixed="right" width="120">
                  <template slot-scope="{ row }">
                    <span class="theme"
                    @click="setScheduleWeekSave(row.id)"
                    >月度排班</span>
                  </template>
                </ElTableColumn>
                <ElTableColumn  label="操作" fixed="right" width="120">
                  <template slot-scope="{ row }">
                    <span class="theme"
                    @click="tapBtn('update', row.id)"
                    >编辑排班</span>
                  </template>
                </ElTableColumn>
            </el-table>
        </el-col>
    </el-row>
    <el-dialog title="编辑排班" :visible.sync="dialogVisible" width="20%">
        <el-form :model="selectDate" label-width="80px">
                    <el-form-item label="日期" >
                        <el-input v-model="selectDate.date"  placeholder="" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="排班类型" >
                         <el-select v-model="selectDate.workType" placeholder="请选择排班类型">
                            <el-option
                                v-for="item in selectDate.workTypeList"
                                :key="item.workType"
                                :label="item.text"
                                :value="item.workType"
                                @click.native = scheduleSave(selectDate.date,item.workType,selectDate.id)
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
    
        </el-form>
    </el-dialog>
    </div>
    
    </template>
    
    <script>
    import { mapGetters } from 'vuex'
    import { staffList, scheduleList, getMonth, setSchedule , setScheduleWeek } from "@/api/staff";
    
    class Options {
        nurse_id = [];
    }
    
    export default {
        name: 'StaffSchedule',
        data() {
            return {
                options: new Options(), // 选项
                workDate: new Date(),
                resData: [],
                list: [],
                $workDate: [],
                dialogVisible:false,
                selectDate:{
                    date:'',
                    workType:'',
                    id:'',
                    workTypeList:[
                            {
                                workType:'0',
                                text:"休"
                            },
                            {
                                workType:'1',
                                text:"全天班"
                            },
                            {
                                workType:'2',
                                text:"上午班"
                            },
                            {
                                workType:'3',
                                text:"下午班"
                            }
                        ]
                },
            }
        },
        computed: {
            ...mapGetters(['userInfo']),
        },
        watch: {},
    
        mounted() {
            //console.log(this.userInfo.shop_id);
            this.getStaffList();
            this.getMonDate();
            
        },
    
        methods: {
            // 获取员工列表
            async getStaffList() {
                staffList({ shopId: this.userInfo.shop_id, page: 1, limit: 1000000 }).then((res) => {
                    this.options.nurse_id = res.list;
                    // this.list = res.list;
                    // console.log(res.list);
    
                    // return;
    
                    const staffIds = [];
                    let arr = res.list;
    
                    arr.forEach(element => {
                        staffIds.push(element.id);
                    });
                    
                    this.getStaffScheduleList(staffIds);
                    
                });
            },
    
            // 获取当月员工排班列表
            async getStaffScheduleList(staffIds) {
                scheduleList({ staffIds: staffIds }).then((res) => {
                    console.log(res);
    
                    this.list = res.data.data;
                })
               
            },
    
            // 获取当月日期
            async getMonDate() {
                getMonth().then((res) => {
                    console.log(res);
                    this.workDate = res;
                })
            },
    
            onChangeTabs(tab, event){
                console.log(tab.label);
            },
    
            onChangeChecked(e){
                console.log(e);
            },
    
            // 点击单元格进行排班
            clickGrids(id,year,item,workType){
                this.dialogVisible=true;
                this.selectDate.date = year+"-"+item;
                this.selectDate.workType=workType;
                this.selectDate.id=id;
                console.log(this.selectDate);
                /*setSchedule({
                    id: id,
                    year: year,
                    date: item,
                    shop_id:this.userInfo.shop_id,
                }).then((res) => {
                    console.log(res);
                })*/
            },
            // 日历
            tapBtn(type, id) {
                switch (type) {
                    case "update":
                    this.$router.push("./updateStaffSchedule?id=" + id+"&shop_id="+this.userInfo.shop_id);
                    break;
                }
            },
            //编辑排班
            scheduleSave(date,workType,id)
            {
                var is_work='';
                var work_type = '';
                switch(workType)
                {
                    case '0':{
                        is_work=0;
                        work_type=0;
                        break;
                    }
                    case '1':{
                        is_work=1;
                        work_type=0;
                        break;
                    }
                    case '2':{
                        is_work=1;
                        work_type=1;
                        break;
                    }
                    case '3':{
                        is_work=1;
                        work_type=2;
                        break;
                    }
                }
                setSchedule({
                    shop_id:this.userInfo.shop_id,
                    id:id,
                    year:date.slice(0,4),
                    data:[
                        {
                            date:date.slice(5),
                            is_work:is_work,
                            work_type:work_type
                        }
                    ]
                }).then((res) => {
                    //console.log(res);
                    if(res.code != 200){
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                    }
                    this.getStaffList();
                    this.getMonDate();
                })
                //console.log(param);
                this.dialogVisible = false;
            },
            //一次排版一周
            setScheduleWeekSave(id){
                setScheduleWeek({
                    shop_id:this.userInfo.shop_id,
                    id:id
                }).then((res) => {
                    //console.log(res);
                    if(res.code != 200){
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                    }
                    this.getStaffList();
                    this.getMonDate();
                })
            }
            
        },
    };
    </script>
    <style>
    .dayValue{
        
    }
    .content{
        width: 100%;
        height:60px;
        line-height: 60px;
        text-align: center;
    }
    .hand-color1{
        background-color:ForestGreen;
        color:white;
    }
    .hand-color2{
        background-color:#dfe2e9  ;
        color:#6b849d;
    }
    .hand-color3{
        background-color:#2bada8;
        color:white;
    }
    .hand-color4{
        background-color:#c96bc2;
        color:white;
    }
    </style>